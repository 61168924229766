<ion-content>
  <ion-list style="margin: 1em;">
    <ion-item (click)="showIntro()">
      <ion-icon slot="start" name="bulb-outline"></ion-icon>
      <ion-label> {{ 'Help' | translate }} </ion-label>
    </ion-item>
    <ion-item (click)="showContact()">
      <ion-icon slot="start" name="mail-outline"></ion-icon>
      <ion-label> {{ 'Contact' | translate }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
