import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate
{
	public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public constructor(private router: Router) { }

	public canActivate(route: ActivatedRouteSnapshot): boolean
	{
		if (!this.isAuthenticated.value)
		{
			this.router.navigate(['login']);
			return false;
		}

		return true;
	}

	public setIsAuthenticated(value: boolean)
	{
		this.isAuthenticated.next(value);
	}
}
