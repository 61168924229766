import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import moment from 'moment';

import { Ad } from '../models/ad';
import { UserInput } from '../models/user-input';
import { Folder } from '../models/folder';
import { CalculationResult } from '../models/calculation-result';
import { AdditionalCost } from '../models/additional-cost';
import { CalculationListItem } from '../models/calculation-list-item';

interface CalcOriginObjects
{
	mycalculator_artikeldaten: object;
	mycalculator_werbeanbringungen: object;
	mycalculator_werbeanbringung_kosten: object;
	mycalculator_werbeanbringung_kosten_optional: object;
}

@Injectable()
export class DataService
{
	public articleData: any = [];
	public adsData: any = [];
	public adsCostData: any = [];
	public adsCostOptData: any = [];
	public defaultMarge: any = [];
	public calculationBasisVersion: number;
	public folders: any = [];
	public calculations: any = [];
	public additionalCostsData: AdditionalCost[] = [];

	// tslint:disable: ext-variable-name
	public STORAGE_ARTICLE_DATA: string = 'article_data';
	public STORAGE_ADS_DATA: string = 'ads_data';
	public STORAGE_ADS_COST_DATA: string = 'ads_cost_data';
	public STORAGE_ADS_COST_OPT_DATA: string = 'ads_cost_opt_data';
	public STORAGE_FORM_VALUES: string = 'agregated_form_values';
	public STORAGE_DEFAULT_MARGE: string = 'default_marge';

	private mainDataPath: string = '/common/ajax.php?bereich=portal&klasse=mycalculator_ajax&modul_id=101&com=laden_inhalte';

	public constructor(private http: HttpClient, private storage: Storage, private translateService: TranslateService) {}

	public async loadAll()
	{
		console.log('Loading data...');  //NOSONAR
		const articles = this.loadArticleData(); // .then(res => { console.log('Articles ', res); return res; });
		const ads = this.loadAdsData();
		const adsCost = this.loadAdsCostsData();
		const adsCostOpt = this.loadAdsCostsOptData();

		Promise.all([articles, ads, adsCost, adsCostOpt]).then((result) => {
			this.setArticlesData(result[0]);
			this.setAdsData(result[1]);
			this.setAdsCostsData(result[2]);
			this.setAdsCostsOptData(result[3]);

			if (this.isAllDataExists())
			{
				console.log('...all data loaded');  //NOSONAR
			}
			else
			{
				console.warn('...data is inconsistent');  //NOSONAR
			}
		});
	}

	public async loadAdditionalCosts(language: string)
	{
		const additionalCosts = await this.loadAdditionalCostsData(language);
		this.setAdditionalCostsData(additionalCosts);
	}

	public getArticlesData()
	{
		return this.articleData;
	}

	private setArticlesData(data: any)
	{
		this.articleData = data;
	}

	public getAdsData()
	{
		return this.adsData;
	}

	private setAdsData(data: any)
	{
		this.adsData = data;
	}

	public getAdsCostsData()
	{
		return this.adsCostData;
	}

	private setAdsCostsData(data: any)
	{
		this.adsCostData = data;
	}

	public getAdsCostsOptData()
	{
		return this.adsCostOptData;
	}

	private setAdsCostsOptData(data: any)
	{
		this.adsCostOptData = data;
	}

	public getDefaultMargeData()
	{
		return this.defaultMarge;
	}

	public setDefaultMargeData(data: any)
	{
		this.defaultMarge = data;
	}

	public getCalculationBasisVersion(): number
	{
		return this.calculationBasisVersion;
	}

	public setCalculationBasisVersion(data: number)
	{
		this.calculationBasisVersion = data;
	}

	public getAdditionalCostsData(): AdditionalCost[]
	{
		return this.additionalCostsData;
	}

	private setAdditionalCostsData(data: any)
	{
		// map Obj of Obj -> Arr of Obj
		this.additionalCostsData = Object.entries(data.mycalculator_frachtkosten).map(e => e[1]) as AdditionalCost[];
	}

	public isAllDataExists()
	{
		if (Object.keys(this.getArticlesData()).length &&
			Object.keys(this.getAdsData()).length &&
			Object.keys(this.getAdsCostsData()).length &&
			Object.keys(this.getAdsCostsOptData()).length)
		{
			return true;
		}

		return false;
	}

	public loadFolders(): Promise<Folder[]>
	{
		const path = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=ordner_laden_liste';
		return this.http.get<Folder[]>(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public addFolder(name: string, lang: string, defaultFolder?: boolean): Promise<any>
	{
		const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
		const pathClass = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=ordner_bearbeiten_daten';
		let pathAction = '&sprache=' + lang + '&folder_id=0&timestamp=' + timestamp + '&name=' + name;
		pathAction += defaultFolder ? '&default=true' : '';
		const path = environment.apiBasePath + pathClass + pathAction;

		return this.http.get(path, { withCredentials: true }).toPromise();
	}

	public deleteFolder(id: number): Promise<any>
	{
		const path = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=ordner_loeschen_daten&folder_id=' + id;
		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public updateFolder(id: number, name: string): Promise<any>
	{
		const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
		const pathClass = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=ordner_bearbeiten_daten';
		const pathAction = '&folder_id=' + id + '&timestamp=' + timestamp + '&name=' + name;
		const path = environment.apiBasePath + pathClass + pathAction;

		return this.http.get(path, { withCredentials: true }).toPromise();
	}

	public sendFolder(id: number, priceType: 'retail' | 'industry', email: string): Promise<any>
	{
		const dataId = '&folder_id=' + id;
		const dataPriceType = '&price=' + priceType;
		const dataEmail = '&email=' + email;
		const path = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=ordner_senden_daten'
			+ dataId + dataPriceType + dataEmail;

		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public loadCalculations(folderId: number): Promise<CalculationListItem[]>
	{
		const path = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=kalkulation_laden_liste&folder_id=' + folderId;
		return this.http.get<CalculationListItem[]>(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public loadCalculation(id: number): Promise<any>
	{
		const path = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=kalkulation_laden_daten&id=' + id;
		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public addCalculutaion(calculation: UserInput, result: CalculationResult, lang: string): Promise<any>
	{
		const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
		const pathClass = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=kalkulation_bearbeiten_daten';
		const pathAction = '&sprache=' + lang + '&id=0&folder_id=' + calculation.folder + '&timestamp=' + timestamp +
			'&name=' + calculation.name + '&calc_input=' + JSON.stringify(calculation) + '&calc_result=' + JSON.stringify(result);
		const path = environment.apiBasePath + pathClass + pathAction;

		return this.http.get(path, { withCredentials: true }).toPromise();
	}

	public deleteCalculation(id: number): Promise<any>
	{
		const path = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=kalkulation_loeschen_daten&id=' + id;
		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public updateCalculation(calculation: CalculationListItem): Promise<any>
	{
		const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
		const pathClass = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=kalkulation_bearbeiten_daten';
		const pathAction = '&id=' + calculation.id + '&folder_id=' + calculation.folder_id + '&timestamp=' + timestamp +
			'&name=' + calculation.name + '&calc_input=' + JSON.stringify(calculation.calc_input);
		const path = environment.apiBasePath + pathClass + pathAction;

		return this.http.get(path, { withCredentials: true }).toPromise();
	}

	public sendCalculation(id: number, priceType: 'retail' | 'industry', email: string): Promise<any>
	{
		const dataId = '&id=' + id;
		const dataPriceType = '&price=' + priceType;
		const dataEmail = '&email=' + email;
		const path = '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=kalkulation_senden_daten'
			+ dataId + dataPriceType + dataEmail;

		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	private async loadArticleData()
	{
		const path = this.mainDataPath + '&filter=mycalculator_artikeldaten';
		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	private async loadAdsData()
	{
		const path = this.mainDataPath + '&filter=mycalculator_werbeanbringungen';
		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	private async loadAdsCostsData()
	{
		const path = this.mainDataPath + '&filter=mycalculator_werbeanbringung_kosten';
		return this.http.get(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	private async loadAdsCostsOptData()
	{
		const path = this.mainDataPath + '&filter=mycalculator_werbeanbringung_kosten_optional';
		return this.http.get<CalcOriginObjects>(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public async loadAdditionalCostsData(language: string)
	{
		const path = this.mainDataPath + '&filter=mycalculator_frachtkosten&sprache=' + language;
		return this.http.get<any>(environment.apiBasePath + path, { withCredentials: true }).toPromise();
	}

	public async getAds(articleNumber: string): Promise<Ad[][]>
	{
		const adsFiltered = this.adsData.mycalculator_werbeanbringungen
			.filter((data: { Artikelnummer: string; }) => data.Artikelnummer === articleNumber) as Ad[];

		for (const ad of adsFiltered)
		{
			// add some additional fields
			// TODO inherit from Ad interface
			ad.available = true;
			ad.selectedColors = 0;
			ad.Werbename = this.mapAdKey(ad) + ' (' + ad.Werbeschluessel + ')';
			const DruckPositionName = this.translateService.instant('druckpositionen.' + ad.Druckposition_id);
			ad.Druckposition_name = DruckPositionName ? DruckPositionName : 'position undefined';

			// Check if ad has no color
			const adsWithoutColor = ['L', 'EL', 'E'];
			for (const adName of adsWithoutColor)
			{
				if (ad.Werbeschluessel.includes(adName))
				{
					ad.selectedColors = 1; // predefine one color
				}
			}
		}

		return this.collectionGroupBy('Druckposition_id', adsFiltered);
	}

	private collectionGroupBy(property: string, collection: any)
	{
		let i = 0;
		let val: any;
		let index: number;
		const values = [];
		const result = [];

		for (; i < collection.length; i++)
		{
			val = collection[i][property];
			index = values.indexOf(val);
			if (index > -1)
			{
				result[index].push(collection[i]);
			}
			else
			{
				values.push(val);
				result.push([collection[i]]);
			}
		}
		return result;
	}

	private mapAdKey(ad: Ad)
	{
		switch (ad.Werbeschluessel)
		{
			case 'T':
				return this.translateService.instant('Pad printing');
			case 'S':
				return this.translateService.instant('Screen printing');
			case 'V':
				return this.translateService.instant('Film transfer printing');
			case 'L':
				return this.translateService.instant('Laser engraving');
			case 'MT':
				return this.translateService.instant('Pad printing');
			case 'HD':
				return this.translateService.instant('Digital printing');
			case 'RD':
				return this.translateService.instant('Relief printing');
			case 'GT':
				return this.translateService.instant('Pad printing');
			case 'MS':
				return this.translateService.instant('Screen printing');
			case 'RL':
				return this.translateService.instant('Laser engraving');
			case 'E':
				return this.translateService.instant('Etching');
			case 'TT':
				return this.translateService.instant('Pad printing');
			case 'P':
				return this.translateService.instant('Special print');
			case 'GS':
				return this.translateService.instant('On rubberised surface');
			case 'EL':
				return this.translateService.instant('Single name engraving');
			case 'D':
				return this.translateService.instant('Doming');
			default:
				return 'na ';
		}
	}
}
