import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DataService } from './services/data.service';
import { IonicSelectableModule } from 'ionic-selectable';
import { APP_LOCALE_ID } from './app-locale';
import { PwaService } from './services/pwa.service';
import { SafeHtmlPipe } from './pipe/safehtml-pipe';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { MomentModule } from 'ngx-moment';
import { PopoverComponent } from './components/popover/popover.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ModalPageFullscreen } from './modal-fullscreen/modal-page-fullscreen';

export function createTranslateLoader(http: HttpClient)
{
	return new TranslateHttpLoader(
		http, environment.apiBasePath + '/common/ajax.php?bereich=portal&modul_id=101&klasse=mycalculator_ajax&com=laden_texte&sprache=', ''
	);
}

const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		domain: environment.domain
	},
	position: 'bottom',
	theme: 'edgeless',
	palette: {
		popup: {
			background: '#f2f2f2',
			text: '#000000',
			link: '#000000'
		},
		button: {
			background: '#545454',
			text: '#ffffff',
			border: 'transparent'
		}
	},
	type: 'info'
};

@NgModule({
	declarations: [
		AppComponent,
		ModalPageFullscreen,
		SafeHtmlPipe,
	],
	entryComponents: [
		ModalPageFullscreen,
		PopoverComponent,
	],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		IonicStorageModule.forRoot(),
		AppRoutingModule,
		BrowserAnimationsModule,
		MatDialogModule,
		HttpClientModule,
		DeviceDetectorModule,
		IonicSelectableModule,
		NgxGoogleAnalyticsModule.forRoot(environment.ga),
		NgxGoogleAnalyticsRouterModule,
		MomentModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient, LOCALE_ID]
			}
		}),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, scope: '/app/mycalculator/' }),
		NgcCookieConsentModule.forRoot(cookieConfig),
	],
	exports: [
		MatTableModule,
		MatStepperModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatOptionModule,
		MatSelectModule,
		MatSliderModule,
		MatTabsModule,
		MatRippleModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatAutocompleteModule,
	],
	providers: [
		StatusBar,
		SplashScreen,
		DataService,
		PwaService,
		{ provide: LOCALE_ID, useValue: APP_LOCALE_ID },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
