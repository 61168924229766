import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit
{
	public constructor(
		private popoverController: PopoverController,
		private navParams: NavParams
	)
	{}

	public ngOnInit()
	{}

	public async showIntro()
	{
		await this.popoverController.dismiss('showIntro');
	}

	public async showContact()
	{
		window.open('https://www.uma-pen.com/de/Unser-Team-1023,20017.html');
		await this.popoverController.dismiss();
	}
}
