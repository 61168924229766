import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
	{
		path: '', redirectTo: 'home', pathMatch: 'full'
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'calculator',
		loadChildren: () => import('./calculator/calculator.module').then(m => m.CalculatorPageModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'folders',
		loadChildren: () => import('./folders/folders.module').then(m => m.FoldersPageModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'calculations',
		loadChildren: () => import('./calculations/calculations.module').then(m => m.CalculationsPageModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
		canActivate: [AuthGuardService]
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'modal-save',
		loadChildren: () => import('./modal-save/modal-save.module').then(m => m.ModalSavePageModule)
	},
	{
		path: 'modal-send',
		loadChildren: () => import('./modal-send/modal-send.module').then(m => m.ModalSendPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})

export class AppRoutingModule { }
