<ion-header *ngIf="showHeader">
    <ion-toolbar color="primary">
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end" class="buttons-last-slot">
            <ion-button (click)="dismissModal()" expand="block" fill="clear" shape="round">
                Close
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content color="primary" class="ion-padding">
    <div class="modal-content" [innerHTML]="content | safeHtml"></div>
</ion-content>
