import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform
{
	public constructor(private sanitized: DomSanitizer) { }

	public transform(value: string)
	{
		return this.sanitized.bypassSecurityTrustHtml(value);
	}
}
