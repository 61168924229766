import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({ providedIn: 'root' })
export class PwaService
{
	public showAddToHomeButton: boolean = false;
	private promptEvent: any;
	// tslint:disable-next-line: ext-variable-name
	private STORAGE_IOS_MESSAGE_DONE: string = 'ios_messge_done';
	private isMessageDoneIOS: boolean = false;

	public constructor(
		private platform: Platform,
		private storage: Storage,
		private gaService: GoogleAnalyticsService,
	)
	{
		storage.get(this.STORAGE_IOS_MESSAGE_DONE).then(done => this.isMessageDoneIOS = done);
	}

	public setPromptEvent(event: any)
	{
		console.log('It\'s a PWA compatible browser 🎉');  //NOSONAR
		this.promptEvent = event;
		this.showAddToHomeButton = true;
	}

	public getPromptEvent()
	{
		return this.promptEvent;
	}

	public addToHomeScreen()
	{
		// Show the prompt
		this.promptEvent.prompt();
		this.gaService.event('mycalc_click_add_to_home', 'mycalc_homescreen_actions', 'Clicked');

		// Wait for the user to respond to the prompt
		this.promptEvent.userChoice
			.then((choiceResult) =>
			{
				if (choiceResult.outcome === 'accepted')
				{
					this.gaService.event('mycalc_click_add_to_home', 'mycalc_homescreen_actions', 'Accepted');
					// hide our user interface that shows our A2HS button
					this.showAddToHomeButton = false;
				}
				this.promptEvent = null;
			});
	}

	// Checks if should display install popup notification:
	public hasToShowInstallMessageIOS()
	{
		if (!this.isMessageDoneIOS && this.platform.is('ios'))
		{
			return true;
		}
		return false;
	}

	public setMessageDone(value: boolean)
	{
		this.storage.set(this.STORAGE_IOS_MESSAGE_DONE, value);
		this.isMessageDoneIOS = value;
	}
}
