import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SwUpdate } from '@angular/service-worker';
import { DataService } from './services/data.service';
import { Storage } from '@ionic/storage';
import { version } from '../../package.json';
import { PwaService } from './services/pwa.service';
import { ModalController } from '@ionic/angular';
import { ModalPageFullscreen } from './modal-fullscreen/modal-page-fullscreen';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent
{
	public appVersion: string = version;

	public constructor(
		public translateService: TranslateService,
		public updates: SwUpdate,
		private dataService: DataService,
		private storage: Storage,
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private pwaServcie: PwaService,
		public modalController: ModalController,
		private deviceService: DeviceDetectorService,
	)
	{
		console.log('App Version ' + version); //NOSONAR

		updates.available.subscribe(event =>
		{
			console.log('current version is', event.current);  //NOSONAR
			console.log('available version is', event.available);  //NOSONAR
			// TODO
			// if (promptUser(event))
			// {
			updates.activateUpdate().then(() => document.location.reload());
			// }
		});

		// register prompt event - add to home button
		window.addEventListener('beforeinstallprompt', (event: any) =>
		{
			event.preventDefault();
			this.pwaServcie.setPromptEvent(event);
		});

		this.initializeApp();
	}

	private async initializeApp()
	{
		if (!this.isCompatibleBrowser())
		{
			const modal = await this.modalController.create({
				component: ModalPageFullscreen,
				componentProps: {
					// tslint:disable-next-line: max-line-length
					content: '<ion-grid> <ion-row class="ion-padding"> <ion-col size="12" class="ion-text-center"> <ion-img src="assets/icons/logo-simple.svg" alt="logo" style="height: 80px;"></ion-img> </ion-col> </ion-row> <ion-row style="padding: 3em; font-size: 16px;"> <ion-col size="12" class="ion-text-center"> Ihr Browser ist mit der myCalculator App nicht kompatibel, bitte versuchen Sie es mit Chrome, Safari, Microsoft Edge 79+ oder Firefox. </ion-col> </ion-row> </ion-grid>'
				}
			});
			await modal.present();

			return; // ! BREAK Init
		}

		this.platform.ready().then(() =>
		{
			if (this.platform.is('cordova'))
			{
				this.statusBar.styleDefault();
				this.splashScreen.hide();
			}
		});

		this.translateService.setDefaultLang('de');
		this.translateService.use('de');
	}

	private isCompatibleBrowser()
	{
		const browser = this.deviceService.getDeviceInfo().browser;

		if (browser === 'IE' || browser === 'MS-Edge')
		{
			return false;
		}
		return true;
	}
}
