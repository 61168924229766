import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
	templateUrl: 'modal-page-fullscreen.html',
	// tslint:disable-next-line: component-selector
	selector: 'modal-page-fullscreen',
})
// tslint:disable-next-line: component-class-suffix
export class ModalPageFullscreen
{
	public showHeader: boolean = false;
	public title: string = '';
	public content: string = '';

	public constructor(
		private modalController: ModalController,
		private navParams: NavParams
	) { }

	public ionViewWillEnter()
	{
		this.showHeader = this.navParams.get('showHeader');
		this.title = this.navParams.get('title');
		this.content = this.navParams.get('content');
	}

	public async dismissModal()
	{
		await this.modalController.dismiss();
	}
}
